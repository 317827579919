import React from "react";
import Info from "./Info/info";
import "./_header.scss";
import Logo from "./Logo/logo";
import { Col } from "reactstrap";

function Header() {
  return (
    <header className="header container-fluid fixed-top">
      <div className="row">
        <Col
          className="container-center justify-content-start"
          xs="3"
          sm="3"
          md="2"
          lg="1"
        >
          <Logo />
        </Col>
        <Col className="container-center text-center">
          <h3 className="club-name">
            <b>English Club</b>
          </h3>
        </Col>
        <Col
          className="container-center justify-content-end"
          xs="3"
          sm="3"
          md="2"
          lg="1"
        >
          <Info />
        </Col>
      </div>
    </header>
  );
}

export default React.memo(Header);
