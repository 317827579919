import React from "react";
import TopicList from "./UpcomingSlotList/upcomingSlotList";
import HeaderSection from "../../components/HeaderSection/headerSection";

function UpcomingSlots() {
  return (
    <>
      <HeaderSection content="Your Upcoming Class" />
      <TopicList />
    </>
  );
}

export default UpcomingSlots;
