import React from "react";
import { useDispatch } from "react-redux";
import { clearData } from "../../utilities/slices/userSlice";

function SignOut(props) {
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("info");
    localStorage.removeItem("user");
    localStorage.removeItem("access");
    localStorage.removeItem("fullname");
    window.location.href = "/login";

    dispatch(clearData());
  };
  return (    
    <div className="modal fade" tabIndex="-1" id="signOutModal" aria-labelledby="signOutModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="signOutModalLabel">Sign out</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to logout?</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={logout}>Sign out</button>
          </div>
        </div>
      </div>
    </div>
  );
}

SignOut.propTypes = {};

export default SignOut;
