import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useState } from 'react';
import "./_appModal.scss";

const AppModal = ({setAppModalOpen, appModalOpen, appModalTitle, appModalMessage, submit}) => {

    const [isProcessing, setIsProcessing] = useState(false);

    const hideAppModal = () => {
      setAppModalOpen(false);
    }

    const handleSubmit = async () => {
      setIsProcessing(true);
      await submit();
      hideAppModal();
      setIsProcessing(false);
    }

    return (
      <>
        <Modal className="app-modal" isOpen={appModalOpen} toggle={hideAppModal}>
          <ModalHeader toggle={hideAppModal}>{appModalTitle ? appModalTitle : 'Confirmation'}</ModalHeader>
          <ModalBody>
            {appModalMessage}
          </ModalBody>
          <ModalFooter>
            <Button className="modal-submit-btn" onClick={handleSubmit} disabled={isProcessing}>
              {isProcessing ? <Spinner color="success"/> : "YES"}
            </Button>
            <Button color="secondary" onClick={hideAppModal} disabled={isProcessing}>
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
}


export default AppModal;