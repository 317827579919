import React from "react";
import SlotList from "./SlotList/SlotList";
import { useParams } from "react-router-dom";
import "./_topicRegistration.scss";

function TopicRegistration() {
  const params = useParams();

  return (
    <div className="topic-registraion">
      <SlotList topicId={params.topicId} />
    </div>
  );
}

export default TopicRegistration;
