import {useNavigate} from "react-router-dom";
import "./_logo.scss";
import logo from "../../../assets/images/logo-magnolia.svg";

function Logo() {
  const navigate = useNavigate();
  const navigateToHome = () => navigate("/");

  return (
      <img src={logo} alt="Magnolia logo" className="logo" onClick={navigateToHome}/>
  );
}

export default Logo;
