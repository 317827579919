import { configureStore } from "@reduxjs/toolkit";
import reducerUser from "../utilities/slices/userSlice";

const rootReducer = {
  user: reducerUser
};
const store = configureStore({
  reducer: rootReducer,
});
export default store;
