import React, { useEffect, useMemo, useState } from "react";
import TopicApi from "../../../api/topicApi";
import SlotCard from "../../../components/TopicRegistration/SlotCard/slotCard";
import { Row, Spinner } from "reactstrap";
import getLinkWithDomain from "../../../helpers/getLinkWithDomain";
import defaultBanner from "../../../assets/images/magnolia-coffee.png";
import "../_topicRegistration.scss";

function SlotList({ topicId }) {
  const [loading, setLoading] = useState(false);
  const [topicDetails, setTopicDetails] = useState();

  async function getTopicDetails() {
    const topicDetails = await TopicApi.getTopicById(topicId);
    setTopicDetails(topicDetails);
  }

  useEffect(() => {
    async function getTopicDetails() {
      setLoading(true);
      const topicDetails = await TopicApi.getTopicById(topicId);
      setTopicDetails(topicDetails);
      setLoading(false);
    }

    getTopicDetails();
  }, [topicId]);

  const userAlreadyRegisteredMaxNumberOfSlots = useMemo(() => {
    if (!topicDetails || !topicDetails.slots) {
      return false;
    }

    return topicDetails.numberOfSlotsBookedByLoggedInUser >= topicDetails.maxSlotsPerStudent;
  }, [topicDetails]);

  const getFileNameFromLink = (link) => {
    if (!link) {
      return "";
    }
    const fileNameWithExtension = decodeURI(
      link.substring(link.lastIndexOf("/") + 1)
    );
    return fileNameWithExtension.split(".").slice(0, -1).join(".");
  };

  const renderMaterialLinks = (materials) => {
    return materials.map((ml, index) => (
      <a className="material-link" key={index} href={getLinkWithDomain(ml)} rel="noreferrer" target="_blank">
        {getFileNameFromLink(ml)}
      </a>
    ));
  };

  const renderTopicDetails = () => {
    return (
      <div className="topic-details">
        <h2 className="text-center">Topic {topicDetails.name}</h2>
        <div dangerouslySetInnerHTML={{ __html: topicDetails.description }} />
        <div className="details-info">
          <div>
            <span className="fw-bold">Teacher:&nbsp;</span>
            <span>{topicDetails.teacher}</span>
          </div>
          <div className="d-flex flex-wrap align-content-start">
            <span className="fw-bold">Materials:&nbsp;</span>
            {topicDetails.materials && topicDetails.materials.length > 0 ? (
              <span className="d-flex flex-wrap align-content-start text-break">
                {renderMaterialLinks(topicDetails.materials)}
              </span>
            ) : 'To be updated!'}
          </div>
        </div>
      </div>
    );
  };

  const renderRegisterSlots = () => {
    return topicDetails.slots.map((slot) => (
      <SlotCard
        userAlreadyRegisteredMaxNumberOfSlots={userAlreadyRegisteredMaxNumberOfSlots}
        maxSlotsPerStudent = {topicDetails.maxSlotsPerStudent}
        key={slot.id}
        topicDetails={topicDetails}
        getTopicDetails={getTopicDetails}
        slot={slot}
      />
    ));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 text-center">
          <Spinner color="success" />
        </div>
      ) : (
        topicDetails && (
          <div>
            <img className="topic-banner" src={topicDetails.banner ? getLinkWithDomain(topicDetails.banner) : defaultBanner} alt="Banner"/>
            {renderTopicDetails()}
            <Row xs="1" sm="1" md="2" lg="3">
              {topicDetails.slots && renderRegisterSlots()}
            </Row>
          </div>
        )
      )}
    </>
  );
}

export default SlotList;
