import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import formatDate from "../../../helpers/formatDate";
import getLinkWithDomain from "../../../helpers/getLinkWithDomain";
import "./_topicCard.scss";
import formatDateTime from "../../../helpers/formatDateTime";
import food from "../../../assets/images/food.png";
import { Badge } from "reactstrap";

function TopicCard(props) {
  const { topic, isUpcomingSlot } = props;

  const navigate = useNavigate();
  const navigateToRegister = () => navigate("/register/" + topic.id);

  const imageLink = getLinkWithDomain(topic.image);

  const getTopicStatusClass = () => {
    let className = '';
    switch(topic.topicStatus) {
      case 'Cannot Register':
        className = 'topic-bagde-expired'
        break;
      case 'Registered':
        className = 'topic-bagde-registered'
        break;
      default:
        className = 'topic-bagde-to-registered';
    }
    return className;
  }

  return (
    <div className="topic-card" onClick={navigateToRegister}>
      <Link to="">
        <div className="topic-photo">
          <img src={imageLink ? imageLink : food} alt="topic" />
        </div>
        <div className="topic-info">
          <div className="topic-bagde">
            {!isUpcomingSlot && <Badge className={getTopicStatusClass()} pill>{topic.topicStatus}</Badge>}
          </div>
          <div className="mb-2">
            <div className="topic-title">Topic {topic.name}</div>
            <div className="topic-details">
              Start Date:{" "}
              {isUpcomingSlot ? (
                <strong>{formatDateTime(topic?.slot.startDate)}</strong>
              ) : (
                formatDate(topic.startDate)
              )}
            </div>
            {!isUpcomingSlot && (
              <div className="topic-details">
                End Date: {formatDate(topic.endDate)}
              </div>
            )}
            <div className="topic-details">Teacher: {topic.teacher}</div>
          </div>
        </div>
      </Link>
    </div>
  );
}

TopicCard.propTypes = {
  topic: PropTypes.object,
};
TopicCard.defaultProps = {
  topic: {},
};
export default React.memo(TopicCard);
