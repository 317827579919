import * as UrlConstant from "../utilities/UrlConstant";
import axiosClientAuthen from "./axiosClientAuthen";

const ReportApi = {
  getReport: async () => {
    const url = `${UrlConstant.GET_REPORT}`;
    return axiosClientAuthen.get(url).then((res) => {
      return res;
    });
  },
};
export default ReportApi;
