import React from "react";
import logo from "../assets/images/logo.gif";

function NotFoundPage(props) {
  return (
    <div className="body-content d-flex align-items-center flex-column">
      <div className="row">
        <div className="col"></div>
        <div className="col">
          <img src={logo} alt="magnolia_logo" title="Magnolia Logo" className="my-5"/>
          <div>
            Oops, the page you have requested is not available. Either its URL
            is incorrect or the page is not available on this channel, i.e. you
            are looking at it on a desktop browser, but the page is only served
            on the smartphone channel.
          </div>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
}

NotFoundPage.propTypes = {};

export default NotFoundPage;
