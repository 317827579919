import * as UrlConstant from "../utilities/UrlConstant";
import axiosClient from "./axiosClient";
import axiosClientAuthen from "./axiosClientAuthen";
import { encode as base64_encode } from "base-64";
const UserApi = {
  login: async (params) => {
    let { username, password } = params;
    const url = `${UrlConstant.LOGIN}`;

    return axiosClient
      .post(
        url,
        {},
        {
          auth: {
            username: username,
            password: password,
          },
        }
      )
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response));
        localStorage.setItem(
          "info",
          base64_encode(
            JSON.stringify({
              ...response,
              username: username,
              access: password,
            })
          )
        );

        return { ...response };
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  getStudyProgress: async () => {
    const url = `${UrlConstant.GET_USER_STUDY_PROGRESS}`;
    return axiosClientAuthen.get(url).then((res) => {
      return res;
    });
  },
};
export default UserApi;
