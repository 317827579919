import { useState, useEffect } from "react";
import UserApi from "../../api/userApi";
import StudyStatistic from "../../components/Study/StudyStatistic/main";
import { Col, Spinner } from "reactstrap";


function GradeAndProgress() {
  const [studyProgress, setStudyProgress] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchStudyProgress() {
      setLoading(true);
      const data = await UserApi.getStudyProgress();
      setStudyProgress(data);
      setLoading(false);
    }
    fetchStudyProgress();
  }, []);

  return loading ? (
    <Col xs="12" sm="12" md="12" lg="12" className="text-center my-2">
      <Spinner color="success" />
    </Col>
  ) : (
      <StudyStatistic studyProgress={studyProgress} />
  );
}

export default GradeAndProgress;