import { Badge, Col, Row } from "reactstrap";
import formatDate from "../../../helpers/formatDate";
import { getEndOfDay } from "../../../helpers/getEndOfDay";
import "./_topicStudyData.scss";

function TopicStudyData(props) {
  const { item, isDisplayDetail, setDisplayDetailId } = props;
  const displayProgressDetail = (id) => {
    if (!isDisplayDetail) {
      setDisplayDetailId(id);
    } else {
      setDisplayDetailId("");
    }
  };
  let hasProgressDetail = false;

  const getTotalScore = () => {
    if (item.statistic.attended)
      return (
        item.statistic.vocabulary +
        item.statistic.grammar +
        item.statistic.fluency +
        item.statistic.communication +
        item.statistic.pronunciation
      );
    return "_";
  };
  const renderTag = () => {
    const endDate = getEndOfDay(item.endDate);
    const now = new Date().getTime();

    if (now > endDate) {
      if (item.statistic) {
        if (item.statistic.attended) {
          hasProgressDetail = true;
          return (
            <i
              className={
                isDisplayDetail ? "fa fa-angle-up" : "fa fa-angle-down"
              }
            ></i>
          );
        } else {
          return (
            <Badge color="warning">Absent</Badge>
          );
        }
      } else {
        return <Badge color="danger">Missed</Badge>;
      }
    } else if (now >= item.startDate) {
      if (item.statistic && item.statistic.attended) {
        hasProgressDetail = true;
        return (
          <i
            className={isDisplayDetail ? "fa fa-angle-up" : "fa fa-angle-down"}
          ></i>
        );
      }
    }
  };

  const renderScore = (score, isAttented) => {
    if (score === 0) {
      if (isAttented) return "Updating";
      return "_";
    }
    return score;
  };

  const tag = renderTag();
  
  return (
    <>
      <div
        className={`progress-container px-3 py-2 d-flex justify-content-between ${
          isDisplayDetail ? "active" : ""
        }`}
        onClick={hasProgressDetail ? () => displayProgressDetail(item.id) : undefined }
      >
        <div className="title">{item.name}</div>
        <div>{tag}</div>
      </div>
      <div
        className={`progress-detail mt-2 px-3 ${
          isDisplayDetail ? "active mb-2" : ""
        }`}
      >
        {item.statistic ? (
          <div className="container-fluid">
            <Row className="pt-5">
              <Col
                className="text-center score-container"
                xs="12"
                sm="6"
                md="6"
                lg
              >
                <div className="title">Vocabulary</div>
                <div className="score">
                  {renderScore(
                    item.statistic.vocabulary,
                    item.statistic.attended
                  )}
                </div>
              </Col>
              <Col
                className="text-center score-container"
                xs="12"
                sm="6"
                md="6"
                lg
              >
                <div className="title">Grammar</div>
                <div className="score">
                  {renderScore(item.statistic.grammar, item.statistic.attended)}
                </div>
              </Col>
              <Col
                className="text-center score-container"
                xs="12"
                sm="6"
                md="6"
                lg
              >
                <div className="title">Fluency</div>
                <div className="score">
                  {renderScore(item.statistic.fluency, item.statistic.attended)}
                </div>
              </Col>
              <Col
                className="text-center score-container"
                xs="12"
                sm="6"
                md="6"
                lg
              >
                <div className="title">Communication</div>
                <div className="score">
                  {renderScore(
                    item.statistic.communication,
                    item.statistic.attended
                  )}
                </div>
              </Col>
              <Col
                className="text-center score-container"
                xs="12"
                sm="6"
                md="6"
                lg
              >
                <div className="title">Pronunciation</div>
                <div className="score">
                  {renderScore(
                    item.statistic.pronunciation,
                    item.statistic.attended
                  )}
                </div>
              </Col>
            </Row>

            <Row className="py-3">
              <Col
                xs="12"
                sm="12"
                md="6"
                className="text-center feedback-container pt-2"
              >
                <div className="pb-2">
                  <u>
                    <strong>Feedback</strong>
                  </u>
                </div>
                {item.statistic.feedback ? (
                  <div className="content mt-2">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.statistic.feedback,
                      }}
                    ></div>
                  </div>
                ) : (
                  "_"
                )}
              </Col>
              <Col
                xs="12"
                sm="12"
                md="6"
                className="text-center feedback-container pt-2"
              >
                <div className="pb-2">
                  <u>
                    <strong>Total Score</strong>
                  </u>
                </div>

                <div className="total-score pb-4">
                  <strong>
                    <span className="user-score">{getTotalScore()}</span>{" "}
                    <span className="splash">/</span>
                    <span className="max-score">20</span>
                  </strong>
                </div>
                <div className="container-fluid px-0">
                  <Row className="">
                    <Col
                      xs="6"
                      sm="6"
                      md="6"
                      lg="6"
                      className="text-center feedback-container"
                    >
                      <div className="title">Date</div>
                      <div className="score">
                        {formatDate(item.slot.startDate)}
                      </div>
                    </Col>
                    <Col
                      xs="6"
                      sm="6"
                      md="6"
                      lg="6"
                      className="text-center feedback-container"
                    >
                      <div className="title">Teacher</div>
                      <div className="score">{item.teacher}</div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

TopicStudyData.propTypes = {};

export default TopicStudyData;
