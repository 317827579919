import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import "./_app.scss";
import ScrollToTop from "./components/ScrollToTop/scrollToTop";
import SignOut from "./components/SignOut/signOut";
import NotFoundPage from "./components/notFoundPage";
import PrivateRoute from "./components/privateRoute";
import Home from "./pages/Home/main";
import Login from "./pages/Login/main";
import TopicRegistration from "./pages/TopicRegistration/main";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GradeAndProgress from "./pages/GradeAndProgress/main";
import Report from "./pages/Report/report";

function App() {
  return (
    <div className="wrapper">
      <SignOut />
      <ScrollToTop />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/register/:topicId" element={<TopicRegistration />} />
            <Route path="/grade-progress" element={<GradeAndProgress />} />
            <Route path="/report" element={<Report />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
