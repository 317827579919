import { decode as base64_decode } from "base-64";
import React from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineBarChart,
  AiOutlineTrophy,
  AiOutlineUser,
  AiOutlineLogout
} from "react-icons/ai";
import avatar from "../../../assets/images/menu.png";
import "./_info.scss";

function Info() {
  let userInfo = localStorage.getItem("info");
  let { fullName, name, admin } = JSON.parse(base64_decode(userInfo));

  const renderUserModal = () => {
    return (
      <div className="dropdown">
        <div className="user-info">
          <AiOutlineUser className="dropdown-icon" />
          <strong>{fullName ? fullName : name}</strong>
        </div>
        <Link to="/grade-progress" className="dropdown-option">
          <AiOutlineTrophy className="dropdown-icon" />
          <strong>Grade & Progress</strong>
        </Link>

        {admin ? (
          <Link to="/report" className="dropdown-option">
            <AiOutlineBarChart className="dropdown-icon" />
            <strong>Report</strong>
          </Link>
        ) : (
          ""
        )}

        <div
          data-bs-toggle="modal"
          data-bs-target="#signOutModal"
          className="dropdown-option"
        >
          <AiOutlineLogout className="dropdown-icon" />
          <strong>Logout</strong>
        </div>
      </div>
    );
  };
  const renderAvatar = () => {
    return (
      <div className="dropdown-switcher">
        <img src={avatar} alt="User Avatar" />
        {renderUserModal()}
      </div>
    );
  };
  return (
    <div className="info-group d-flex flex-column align-items-end">
      <div className="user-icon">{renderAvatar()}</div>
    </div>
  );
}

export default Info;
