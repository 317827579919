import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import TopicApi from "../../../api/topicApi";
import TopicCard from "../../../components/Topic/TopicCard/topicCard";

function TopicList({ year }) {
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    async function fetchTopics() {
      setLoading(true);
      const listTopics = await TopicApi.getAllTopics();
      setTopics(listTopics);
      setLoading(false);
    }
    fetchTopics();
  }, []);

  const renderTopicCards = (topics) => {
    return topics.length !== 0
      ? topics.map((topic, index) => (
          <Col key={index} xs="12" sm="6" md="4" lg="4">
            <TopicCard topic={topic} key={topic.id} />
          </Col>
        ))
      : "";
  };

  const renderedTopics = topics.filter(tp => new Date(tp.startDate).getFullYear() === +year);

  return (
    <React.Fragment>
      <Row>
        {loading ? (
          <Col xs="12" sm="12" md="12" lg="12" className="text-center">
            <Spinner color="success" />
          </Col>
        ) : (
          topics && renderTopicCards(renderedTopics)
        )}
      </Row>
    </React.Fragment>
  );
}

export default TopicList;
