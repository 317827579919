import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { updateLoggedInStatus } from "../utilities/slices/userSlice";
import Header from "./Header/header";
import PageBreadcrumb from "./Breadcrumb/main";

function PrivateRoute() {
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const status = localStorage.getItem("user");

  const checkLoggedInStatus = (status, isLoggedIn) => {
    if (status === undefined && isLoggedIn)
      dispatch(updateLoggedInStatus({ isLoggedIn: false }));
  };

  checkLoggedInStatus(status, isLoggedIn);

  return status ? (
    <React.Fragment>
      <div className="main-content">
        <div className="body-content">
          <Header />
          <div className="wrapper-dashboard">
            <div className="page-grid-view">
              <PageBreadcrumb />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <Navigate
      to={{
        pathname: `/login`,
        state: { referrer: location },
      }}
    />
  );
}

PrivateRoute.propTypes = {};

export default PrivateRoute;
