export function getCurrentYear() {
  return new Date().getFullYear();
}


export function getDataYears() {
  let yearList = [];
  const currentYear = new Date().getFullYear();
  for (let i = 2023; i <= currentYear; i++) {
    yearList.push(i);
  }
  return yearList;
}