import * as UrlConstant from "../utilities/UrlConstant";
import axiosClientAuthen from './axiosClientAuthen';

const TopicApi = {
  getAllTopics: async () => {
    const url = `${UrlConstant.GET_ALL_TOPICS}`;
    return axiosClientAuthen.get(url);
  },
  getTopicById: async (topicId) => {
    const url = `${UrlConstant.GET_TOPIC}` + topicId;
    return axiosClientAuthen.get(url);
  },
  registerTopic: async (payload) => {
    const url = `${UrlConstant.REGISTER_TOPIC}`;
    return axiosClientAuthen.post(url, payload);
  },
  cancelTopic: async (payload) => {
    const url = `${UrlConstant.CANCEL_TOTPIC}`;
    return axiosClientAuthen.post(url, payload);
  },
  getUpcomingSlots: async () => {
    const url = `${UrlConstant.GET_UPCOMING_SLOTS}`;
    return axiosClientAuthen.get(url);
  }
};

export default TopicApi;

