import React from "react";
import "./_home.scss";
import Topic from "../Topic/main";
import UpcomingSlots from "../UpcomingSlot/main";

function Home(props) {
  
  return (
    <div>
      <UpcomingSlots />
      <Topic />
    </div>
  );
}

Home.propTypes = {};

export default Home;
