export const LOGIN = "/.rest/english-club/login";
export const GET_ALL_TOPICS = "/.rest/english-club/topics";
export const GET_TOPIC = "/.rest/english-club/topics/";
export const REGISTER_TOPIC = "/.rest/english-club/register";
export const GET_UPCOMING_SLOTS = "/.rest/english-club/upcoming-topics";
export const CANCEL_TOTPIC = "/.rest/english-club/cancel";
export const GET_USER_STUDY_PROGRESS = "/.rest/english-club/progress/user";

// ADMIN ROLE
export const GET_REPORT = "/.rest/admin/english-club/report";
