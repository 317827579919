import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import TopicApi from "../../../api/topicApi";
import TopicCard from "../../../components/Topic/TopicCard/topicCard";

function UpcomingSlotList() {
  const [loading, setLoading] = useState(false);
  const [upcomingSlots, setUpcomingSlots] = useState();
  useEffect(() => {
    async function fetchUpcomingSlots() {
      setLoading(true);
      const result = await TopicApi.getUpcomingSlots();
      setUpcomingSlots(result);
    }
    if (!upcomingSlots) {
      fetchUpcomingSlots();
    } else {
      setLoading(false);
    }
  }, [upcomingSlots]);

  const renderSlotCards = (topics) => {
    return topics.length !== 0 ? (
      topics.map((topic, index) => (
        <Col key={index} xs="12" sm="6" md="4" lg="4">
          <TopicCard topic={topic} key={topic.id} isUpcomingSlot={true} />
        </Col>
      ))
    ) : (
      <div className="">
        <em>No upcoming events. Please register to improve your skills.</em>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Row>
        {loading ? (
          <Col xs="12" sm="12" md="12" lg="12" className="text-center">
            <Spinner color="success" />
          </Col>
        ) : (
          upcomingSlots && renderSlotCards(upcomingSlots)
        )}
      </Row>
    </React.Fragment>
  );
}

export default UpcomingSlotList;
