import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  login,
  updateLoggedInStatus
} from "../../utilities/slices/userSlice";
import "./_login.scss";
import { Col } from "reactstrap";

function Login(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  const { isLoggedIn, error } = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const handleChangeInputText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setInfo({ ...info, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    async function submitToLogin() {
      await dispatch(login(info));
    }
    submitToLogin();
  };

  useEffect(() => {
    const checkLoggedInStatus = () => {
      const status = localStorage.getItem("user");
      if (status === undefined && isLoggedIn)
        dispatch(updateLoggedInStatus({ isLoggedIn: false }));
    };
    checkLoggedInStatus();
    if (isLoggedIn) {
      if (location.state?.referrer.pathname) {
        navigate(location.state.referrer.pathname);
      } else {
        navigate(`/`);
      }
    }
  }, [isLoggedIn, navigate, location, dispatch]);

  return (
    <div className="loginBackground container-fluid">
      <div className="row">
        <Col xs="1"
          sm="1"
          md="2"
          lg="2"></Col>
        <Col className="d-flex login-content">
          <div className="animationBg">
            <form onSubmit={handleSubmit} className="loginForm" id="loginForm">
              <p className="loginTitle">Magnolia English Club</p>
              <p>Username</p>
              <input
                name="username"
                onChange={handleChangeInputText}
                required
                placeholder="Enter your username"
              />
              <p>Password</p>
              <input
                name="password"
                onChange={handleChangeInputText}
                required
                type="password"
                placeholder="Enter your password"
              />
              <div className="text-center mb-4">
                <button onClick={handleSubmit} className="btn login-btn" type="submit">
                  Get Started
                </button>
              </div>
            </form>
            <div className="text-danger text-center">{error}</div>
          </div>
        </Col>
        <Col xs="1"
          sm="1"
          md="2"
          lg="2"></Col>
      </div>
    </div>
  );
}

Login.propTypes = {};

export default Login;
