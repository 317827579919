import YearStudyProgress from "../YearStudyProgress/main";
import { getDataYears } from "../../../utilities/dateTimeUtils";

const StudyStatistic = ({studyProgress, adminView}) => {
  return (
    <>
      {getDataYears().map((year, index) => {
        const yearStudyProgress = studyProgress.filter(sp => new Date(sp.startDate).getFullYear() === year);
        return (
          <YearStudyProgress
            key={index}
            year={year}
            studyProgress={yearStudyProgress}
            adminView={adminView}
          />
        );
      })}
    </>
  );
}

export default StudyStatistic;
