import { useState } from "react";
import "./_yearStudyProgress.scss";
import TopicStudyData from "../TopicStudyData/main";
import { getCurrentYear } from "../../../utilities/dateTimeUtils";

function YearStudyProgress({year, studyProgress, adminView}) {
  const [displayDetailId, setDisplayDetailId] = useState("");
  const [isOpen, setIsOpen] = useState(year === getCurrentYear());
  const toggle = () => setIsOpen(!isOpen);

  const renderAttendanceCheckSize = () => {
    const data = studyProgress.filter(
      (item) => item.statistic && item.statistic.attended
    );
    if (data) {
      return data.length;
    }
    return 0;
  };

  return (      
      <div className="year-study-progress">
        <div className="year-toggle" onClick={toggle}>
          <div className="year">{year}</div>
          <i className={isOpen ? "fa fa-angle-up" : "fa fa-angle-down"} />
        </div>
        <div className={`year-data ${isOpen ? "active" : ""}`}>
          <div>
            {studyProgress && studyProgress.length > 0 ? (
              <>
                <div className="mb-3">
                  <div>
                    {adminView ? "Attendance check" : "Your attendance check"}:{" "}
                    <strong>
                      {" "}{renderAttendanceCheckSize()} / {studyProgress.length}
                    </strong>
                  </div>
                </div>
                <div>
                  <div className="mb-2">{adminView ? "Study progress" : "Your study progress"}:</div>
                  {studyProgress &&
                    studyProgress.map((item) => (
                      <TopicStudyData
                        item={item}
                        key={item.id}
                        setDisplayDetailId={setDisplayDetailId}
                        isDisplayDetail={displayDetailId === item.id}
                      />
                    ))}
                </div>
              </>
            ) : (
              <div className="no-progress">
                  No Progress
              </div>
              )}
          </div>
        </div>
      </div>
  );
}

export default YearStudyProgress;
