import React, { useState } from "react";
import TopicApi from '../../../api/topicApi';
import { Card, CardBody, Button, Tooltip } from "reactstrap";
import moment from 'moment';
import { BsPeopleFill, BsClockFill , BsCalendarWeekFill, BsFillCalendarXFill } from "react-icons/bs";
import AppModal from "../../AppModal/AppModal";
import { toast } from "react-toastify";
import googleCaLendarIcon from "../../../assets/images/google-calendar-icon.png";
import "./_slotCard.scss";

function SlotCard({ slot, topicDetails, getTopicDetails, userAlreadyRegisteredMaxNumberOfSlots, maxSlotsPerStudent}) {
  const [submitBtnTooltipOpen, setRegisterBtnTooltipOpen] = useState(false);
  const [calendarBtnTooltipOpen, setCalendarBtnTooltipOpen] = useState(false);
  const [appModalOpen, setAppModalOpen] = useState(false);
  const [appModalData, setAppModalData] = useState({});

  const submitBtnTooltipToggle = () => setRegisterBtnTooltipOpen(!submitBtnTooltipOpen);
  const calendarBtnTooltipToggle = () => setCalendarBtnTooltipOpen(!calendarBtnTooltipOpen);

  const SLOT_EXPIRED_MSG = 'The class is expired!';
  const REGISTRATION_TIME_LIMIT_MSG = 'Registration time limit exceeded!';
  const isSlotFull = slot.numberOfRegisteredStudents >= topicDetails.numberOfStudents;

  const formatDate = (dateString) => {
    return moment(dateString).format("ddd, MMM Do YYYY, HH:mm");
  }

  const startDateStr = formatDate(slot.startDate);
  const startTime = new Date(slot.startDate);
  const currentTime = new Date();
  const isSlotExpired = startTime < currentTime;
  const registrationLimitTime = new Date(slot.registrationLimitDate);
  const isRegistrationTimeLimitExceeded = registrationLimitTime < currentTime;

  const isCancelBtnEnabled = !isSlotExpired && !isRegistrationTimeLimitExceeded;
  const isRegisterBtnEnabled = !userAlreadyRegisteredMaxNumberOfSlots && !isSlotFull && !isSlotExpired && !isRegistrationTimeLimitExceeded;
  const isSubmitBtnEnabled = slot.bookedByLoggedInUser ? isCancelBtnEnabled : isRegisterBtnEnabled;


  const getRegisterBtnToolTipMessage = () => {
    let toolTipMessage = '';
    if (isSlotExpired) {
      toolTipMessage = SLOT_EXPIRED_MSG;
    } else if (userAlreadyRegisteredMaxNumberOfSlots) {
      toolTipMessage = `You have already registered (${maxSlotsPerStudent}) slots!`;
    } else if (isRegistrationTimeLimitExceeded) {
      toolTipMessage = REGISTRATION_TIME_LIMIT_MSG;
    } else if (isSlotFull) {
      toolTipMessage = 'This slot is is fully booked!';
    }
    return toolTipMessage;
  };


  const getCancelBtnToolTipMessage = () => {
    let toolTipMessage = '';
    if (isSlotExpired) {
      toolTipMessage = SLOT_EXPIRED_MSG;
    } else if (isRegistrationTimeLimitExceeded) {
      toolTipMessage = REGISTRATION_TIME_LIMIT_MSG;
    }
    return toolTipMessage;
  }

  const submitBtnToolTipMessage = slot.bookedByLoggedInUser ? getCancelBtnToolTipMessage() : getRegisterBtnToolTipMessage();

  const toastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: {
      top: 70
    }
  };

  const registerTopic = async () => {
    try {
      await TopicApi.registerTopic({ 'slotId': slot.id });
      toast.success('You have registered successfully!', toastOptions);
    } catch (error) {
      toast.error('Error occurred while registering the topic!', toastOptions);
    }

    await getTopicDetails();
  }

  const cancelTopic = async () => {
    try {
      await TopicApi.cancelTopic({ 'slotId': slot.id });
      toast.success('You have canceled successfully!', toastOptions);
    } catch (error) {
      toast.error('Error occurred while canceling the topic!', toastOptions);
    }

    await getTopicDetails();
  }

  const handleOnClickRegisterBtn = () => {
    const data = {
      confirmMessage: getConfirmMessage('register'),
      submitFunc: registerTopic
    }

    setAppModalData(data);
    setAppModalOpen(true);
  }

  const handleOnClickCancelBtn = () => {
    const data = {
      confirmMessage: getConfirmMessage('cancel'),
      submitFunc: cancelTopic
    }

    setAppModalData(data);
    setAppModalOpen(true);
  }

  const handleOnClickSubmitBtn = () => {
    slot.bookedByLoggedInUser ? handleOnClickCancelBtn() : handleOnClickRegisterBtn();
  }

  const getConfirmMessage = (action) => {
    return <><span>Do you want to {action} </span><strong>Topic {topicDetails.name  + ' - '+ startDateStr}</strong>?</>;
  }

  const getSubmitBtnClass = () => {
    let defaultClass = 'slot-submit-btn';
    if (!isSubmitBtnEnabled) {
      return defaultClass;
    }
    return slot.bookedByLoggedInUser ? (defaultClass + ' slot-cancel-btn') : (defaultClass + ' slot-register-btn');
  }

  return (
    <>
      { appModalOpen && <AppModal appModalOpen={appModalOpen} setAppModalOpen={setAppModalOpen} appModalMessage={appModalData.confirmMessage} submit={appModalData.submitFunc}/> }
      <div className="slot-card">
        <Card
          style={{
            border: slot.bookedByLoggedInUser ? '#599900 2px solid' : 'none'
          }}
        >
          <CardBody>
            <div>
              <BsCalendarWeekFill className="slot-icon"/>
              <span className="slot-start-date">{startDateStr}</span>
            </div>
            <div className="slot-info">
              <span><BsClockFill className="slot-icon"/></span>
              <span className="align-middle">Duration: {topicDetails.duration + ' min(s)'}</span>
            </div>
            <div className="slot-info">
              <span><BsFillCalendarXFill className="slot-icon"/></span>
              <span className="align-middle">Due Date: {moment(slot.registrationLimitDate).format("MMM Do YYYY, HH:mm")}</span>
            </div>
            <div className="slot-info">
              <BsPeopleFill className="slot-icon"/>
              <span className="align-middle">Registered: {slot.numberOfRegisteredStudents + '/' + topicDetails.numberOfStudents}</span>
            </div>
            <div className="bottom-btn-container">
              <div>
                <span id={"submitButtonCover_" + slot.id} className="d-inline-block">
                  <Button className={getSubmitBtnClass()}
                    disabled={!isSubmitBtnEnabled}
                    onClick={handleOnClickSubmitBtn}
                  >
                    {slot.bookedByLoggedInUser ? 'Cancel' : 'Register'} 
                  </Button>
                </span>
              </div>
              {!isSubmitBtnEnabled &&
                (<Tooltip
                  placement="top"
                  isOpen={submitBtnTooltipOpen}
                  target={"submitButtonCover_" + slot.id}
                  toggle={submitBtnTooltipToggle}
                >
                  {submitBtnToolTipMessage}
                </Tooltip>)}
              {slot.bookedByLoggedInUser && !isSlotExpired &&
                (<>
                  <div className="d-flex align-items-center">
                    <a href={slot.googleCalendarLink} target="_blank" rel="noreferrer">
                      <img id={"googleCalendarBtn_" + slot.id} src={googleCaLendarIcon} className="google-calendar-btn" alt="Calendar Icon"/>
                    </a>
                  </div>
                  <Tooltip
                    placement="top" 
                    isOpen={calendarBtnTooltipOpen}
                    target={"googleCalendarBtn_" + slot.id}
                    toggle={calendarBtnTooltipToggle}
                  >
                      Add to my Google Calendar
                  </Tooltip>
                </>)}
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default SlotCard;
