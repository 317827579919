import { useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import './_breadcrumb.scss';
import {  AiOutlineHome } from "react-icons/ai";

const PageBreadcrumb = () => {
  const navigate = useNavigate();

  let nameMapping = new Map([
    ["register", 'Topic Registration'],
    ["report", 'Report'],
    ["grade-progress", 'Grade & Progress']
  ]);

  const paths = window.location.pathname.split('/').slice(1);
  const isUUID = (str) => {
    const uuidRegexPattern =  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return str.match(uuidRegexPattern);
  }
  const sanitizedPaths = paths.filter(path => path && !isUUID(path));
  const homeOnly = sanitizedPaths.length === 0;
  const navigateToHome = () => navigate("/");

  return (
    <div className="page-breadcrumb">
      <Breadcrumb>
        <BreadcrumbItem className={`home-item ${homeOnly ? 'active' : ''}`}>
          <AiOutlineHome className="home-icon" onClick={navigateToHome} /> <span>{ homeOnly ? 'Home' : <a href="/">Home</a> }</span>
        </BreadcrumbItem>
        {
          sanitizedPaths.map((path, index) => {
            return <BreadcrumbItem key={index} className={index === sanitizedPaths.length - 1 ? 'active' : ''}>{nameMapping?.has(path) ? nameMapping.get(path) : path}</BreadcrumbItem>
          })
        }
      </Breadcrumb>
    </div>
  )
}

export default PageBreadcrumb;