import React from "react";
import TopicList from "./TopicList/topicList";
import "./_topic.scss";
import HeaderSection from "../../components/HeaderSection/headerSection";
import { useState } from "react";
import { getCurrentYear, getDataYears } from "../../utilities/dateTimeUtils";


function Topic() {
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const onSelectYear = (event) => {
    setSelectedYear(event.target.value);
  }

  return (
    <div className="topic">
      <HeaderSection content="Topics" />
      <select onChange={onSelectYear} value={selectedYear} className="year-select">
        {getDataYears().map((year, index) => <option key={index} value={year}>{year}</option>)}
      </select>
      <TopicList year={selectedYear}/>
    </div>
  );
}

export default Topic;
