import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import ReportApi from "../../api/reportApi";
import "./_report.scss";
import { useNavigate } from "react-router-dom";
import StudyStatistic from "../../components/Study/StudyStatistic/main";

const Report = () => {
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dropdownOpen, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchReport() {
      setLoading(true);
      const data = await ReportApi.getReport().catch(() => {
        // Prevent member user view this page
        navigate("/unauthorized");
      });
      setReport(data);
      setLoading(false);
    }
    fetchReport();
  }, []);

  const onChangeReportMember = (item) => {
    if (!selectedItem || item.user.id !== selectedItem.user.id) {
      setSelectedItem(item);
    }
  };

  return loading ? (
    <Col xs="12" sm="12" md="12" lg="12" className="text-center my-2">
      <Spinner color="success" />
    </Col>
  ) : (
    <div className="report">
      {report && (
        <>
          <Dropdown
            toggle={() => {
              setOpen(!dropdownOpen);
            }}
            isOpen={dropdownOpen}
          >
            <DropdownToggle
              variant="success"
              className="report-toggle d-flex justify-content-between align-items-center"
            >
              {selectedItem
                ? selectedItem.user.fullName
                : "Select member"}
              <i className="fa fa-angle-down"></i>
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {report.map((item, index) => (
                <DropdownItem
                  onClick={() => onChangeReportMember(item)}
                  key={index}
                >
                  {item.user.fullName}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <div className="user-study-statistic">
            {selectedItem && selectedItem.statistics && <StudyStatistic studyProgress={selectedItem.statistics} adminView={true} />}
          </div>
        </>
      )}
    </div>
  );
}

export default Report;
