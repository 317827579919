import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserApi from "../../api/userApi";

// thunk action to login and get token
export const login = createAsyncThunk("user/login", async (params) => {
  const token = await UserApi.login(params);
  return token;
});

export const initialStateUseLoggedIn = () => {
  let result = localStorage.getItem("user");
  return result === undefined || result === null ? false : true;
};

const user = createSlice({
  name: "user",
  initialState: {
    data: {
      isLoggedIn: initialStateUseLoggedIn(),
      error: "",
    },
  },
  reducers: {
    updateLoggedInStatus: (state, action) => {
      state.data.isLoggedIn = action.payload.isLoggedIn;
    },
    clearData: (state) => {
      state.data.isLoggedIn = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.data.isLoggedIn = true;
      state.data.error = "";
    });

    builder.addCase(login.rejected, (state, action) => {
      state.data.error = "Username or password is incorrect";
    });
  },
});
export default user.reducer;
export const { updateLoggedInStatus, clearData } = user.actions;
